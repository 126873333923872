@import '../variables';

.card {
  min-height: 8.8rem;
  text-align: left;
  border-radius: 0;

  .blackShadow {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
  }
  .blueShadow {
    box-shadow: 0 4px 20px 0 #0062ff80;
  }

  .MuiCardHeader-action > * {
    padding: 0;
    width: 3.2rem;
    height: 3.2rem;
  }
  .MuiCardHeader-content {
    flex-shrink: 1;
    overflow: auto;
    //word-break: break-all;
  }
  &.inactive {
    background-color: rgba(0, 0, 0, 0.09);
  }

  .label {
    font-size: 1.2rem;
    color: $color-light-text-label;
  }
  .primary {
    font-weight: bold;
    font-size: 1.6rem;
    color: $color-grey-1000;
  }
  .secondary {
    font-size: 1.2rem;
    color: $color-black;
  }

  .darkTheme & {
    background-color: $color-dark-background-secondary;
    color: $color-dark-text;

    .label {
      color: $color-dark-label;
    }
    .primary,
    .secondary {
      color: $color-dark-text;
    }
    .MuiCardHeader-action > * {
      padding: 0;
      width: 3.2rem;
      height: 3.2rem;
    }
    &.inactive {
      background-color: lighten($color-dark-background-secondary, 20%);
    }
  }
}
