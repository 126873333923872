@media print {
  //@page {
  //  size: auto;
  //  margin: 20mm 10mm 10mm 10mm;
  //}
  //body {
  //  margin: 0;
  //  padding: 0;
  //}

  @page {
    margin: 0;
  }
  body {
    margin: 1.6cm;
  }

  //long tables
  table {
    page-break-inside: auto;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }

  .noPrint {
    display: none !important;
  }

  aside {
    display: none !important;
  }

  #secondaryNav-portal {
    display: none;
  }

  .layoutWrapper {
    height: auto !important;
    width: auto !important;
    overflow: hidden;
  }
}
