@import '../../../../../styles/variables.scss';

.container {
  width: 41rem;
  min-height: 8rem;
  border: 2px solid $color-light-border;
  display: inline-block;
  background: white;

  &:global(.MuiCard-root) {
    overflow: visible;
  }

  &.selectable {
    cursor: pointer;
    &:hover {
      border: 0.2rem dashed $primary-color;
    }
  }

  &.current {
    border: 0.2rem solid $primary-color;
  }

  &.notSelectable {
    background-color: $color-light-background;
    //border-color: $color-light-background;
    cursor: not-allowed;
  }

  &.retired {
    background-color: $color-light-border;
    //border-color: $color-light-border;
  }
  .tileItem {
    padding: 0px !important;
  }
}
