@import '../../../../styles/variables';

.wrapper {
  background: white;
}

.container {
  padding-top: 2rem;
  padding-bottom: 1rem;
  min-height: 16rem;
}

.colorLine {
  width: 100%;
  height: 48px;
  background: #69b8f7;

  &.manualEntry {
    background: $color-teal-400;
  }

  &.basedOnMetadata {
    background: $primary-color;
  }
}

.title {
  font-weight: 300;
  font-size: 2.6rem;
  color: #0c2340;
}

.subTitle {
  font-weight: 300;
  font-size: 2.1rem;
  color: #0c2340;
}

.aside {
  display: inline-block;
  font-weight: 300;
  font-size: 2.1rem;
  color: #0c2340;
  margin-right: 2rem;
}
