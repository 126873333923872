@import '../../../styles/variables';

.colScenarioNameHeading {
  width: calc(20vw + 10rem);
}

.colModelNameHeading {
  width: calc(20vw + 10rem);
}

.colUserHeading {
  width: 15rem;
}

.colDateHeading {
  width: 19.5rem;
}
.colStatusHeading {
  width: 13rem;
}

.restricted {
  color: grey;
}

.newChangesText {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  color: $color-darkGrey-text2;
}

:global(.flashRow) {
  td {
    background-color: #{$primary-color} !important;
    transition: background-color 0s;
  }
}

:global(.flashedRow) {
  td {
    transition: background-color 3s;
  }
}

.emptyCell {
  border-bottom: 0.1rem solid #4c4c4c;
  display: table-cell;
  width: 2%;
}

.instanceLink {
  border-bottom: 0.1rem solid #4c4c4c;
  display: table-cell;
  width: 25%;
}
.fileName {
  border-bottom: 0.1rem solid #4c4c4c;
  display: table-cell;
  width: 22%;
}
.modelLink {
  border-bottom: 0.1rem solid #4c4c4c;
  display: table-cell;
  width: 26.8%;
}
.createdBy {
  border-bottom: 0.1rem solid #4c4c4c;
  display: table-cell;
  width: 8%;
}
.date {
  border-bottom: 0.1rem solid #4c4c4c;
  display: table-cell;
  width: 10.7%;
}
.status {
  border-bottom: 0.1rem solid #4c4c4c;
  display: table-cell;
}
