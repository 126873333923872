@import '../../../../styles/variables';

.wrapper {
  width: calc(100% - 10px);
  overflow: auto;
}

.doublePaneWrapper {
  width: calc(100% - 10px);
  overflow: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > div {
    width: calc(50% - 5px);
    overflow: auto;
    padding: 2px;
  }

  table {
    min-width: calc(100% - 1px);
  }
}

.container {
  border-collapse: collapse;

  tr {
    height: 4rem;
    &:focus {
      outline: 0;
    }
    td {
      min-width: 5rem;
    }
  }

  tr.rowWithChange {
    & > td,
    & > th {
      background-color: lighten($color-indigo-50, 10%);

      &:global(.marked) {
        border: 4px solid #{$primary-color} !important;
        &:focus {
          outline: 0;
        }
      }
    }

    &:global(.marked) {
      & > td,
      & > th {
        border-top: 4px solid #{$primary-color} !important;
        border-bottom: 4px solid #{$primary-color} !important;
      }
    }
  }

  &.oldTable,
  &.singlePane {
    :global(.marked) {
      & > td,
      & > th {
        &:first-child {
          border-left: 4px solid #{$primary-color} !important;
        }
      }
    }
  }
  &.newTable,
  &.singlePane {
    :global(.marked) {
      & > td,
      & > th {
        &:last-child {
          border-right: 4px solid #{$primary-color} !important;
        }
      }
    }
  }

  td {
    background-color: #fff;
    border: 1px solid #{$color-light-border} !important;

    :global(.diff-code-delete) {
      background-color: #e45050;
      color: #fff;
    }

    :global(.diff-code-insert) {
      background-color: #6eceb2;
      color: #0c2340;
    }
  }
}
