body {
  .container {
    padding: 2rem 0rem;
    position: relative;

    .label {
      font-size: 1.2rem;
      line-height: 1.2rem;
    }

    .title {
      font-size: 2rem;
      line-height: 2.6rem;
    }

    .icon {
      position: absolute;
      left: -4.2rem;
      top: 2rem;
    }
  }
}
