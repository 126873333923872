@import '../../../../styles/variables';

.cardSpacer {
  height: 0.5rem;
  background-color: $color-white;
  font-size: 1px;
}

.clickable {
  cursor: pointer;
}

.row {
  overflow: hidden;
  position: relative;
  background-color: $color-light-background;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 10px;
    background-color: white;
    //border: 1px solid green;
  }

  td {
    padding: 1rem 1rem 5rem;
    vertical-align: top;
    background-clip: padding-box;

    &:first-of-type {
      padding-left: 0;
      padding-bottom: 6rem;
      position: relative;

      &:before {
        content: attr(data-owners);
        position: absolute;
        color: $color-light-placeholder;
        left: 1rem;
        bottom: 2rem;
        width: 700px;
      }

      &:after {
        content: '';
        position: absolute;
        left: -1px;
        top: 0;
        height: 100%;
        width: 2px;
        background-color: var(--mark-color);
      }
    }

    //&:nth-of-type(2) {
    //  position: relative;
    //  &:after {
    //    content: '';
    //    position: absolute;
    //    left: -100px;
    //    bottom: 0px;
    //    height: 1rem;
    //    background-color: red;
    //    border: 1px solid green;
    //    width: 2000px;
    //  }
    //}
  }

  :global(.MuiCheckbox-root) {
    margin-top: -9px;
    margin-right: -9px;
  }
}

.status > div > div {
  top: 0 !important;
  & > span {
    white-space: nowrap;
  }
}
