.container,
.dynamicContainer {
  display: block;
  overflow: auto;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  padding: 4rem 0;
}
.dynamicContainer {
  width: 38%;
}
