@import '../variables';

.listPanel {
  background-color: $color-light-background;
  display: flex;
  justify-content: flex-end;

  .darkTheme & {
    background-color: $color-dark-background;
  }

  .darkGreyTheme & {
    background-color: $color-darkGrey-background;
  }

  .MuiFormControl-marginDense {
    margin: 0;
    min-width: 33rem;
  }
}

.MuiTablePagination-root {
  hr {
    width: 1px;
    height: 52px;
    background: $color-light-border;
    margin: 0 2rem;
  }

  .MuiTablePagination-actions {
    margin: 0;
  }

  .MuiToolbar-root {
    padding-left: 1rem;
  }

  .MuiTablePagination-selectRoot {
    margin: 0;
  }

  .MuiSelect-root {
    background: transparent !important;
    border: unset !important;
    color: inherit !important;

    & ~ svg {
      margin-right: -5px;
    }
  }
}

.MuiTable-root {
  //.MuiTableRow-root {
  //  td {
  //    transition: background-color 3s;
  //  }
  //}

  //td {
  //  word-break: break-all;
  //}

  .rowSelected {
    background: $color-indigo-100;
    &:hover {
      background: $color-indigo-300 !important;
    }
    .MuiTableCell-body {
      color: white;
    }
  }

  background-color: $color-light-background;
  .MuiTableCell-head {
    background-color: $color-light-border;
  }
}

.darkTheme {
  //Table grid
  .MuiTable-root {
    background-color: $color-dark-border;

    .MuiTableRow-root {
      &:hover {
        .MuiTableCell-body {
          background: $color-dark-background-secondary !important;
          color: white;
        }
      }
    }

    .rowSelected {
      &:hover {
        .MuiTableCell-body {
          background: $color-dark-background-secondary !important;
          color: white;
        }
      }
      .MuiTableCell-body {
        background: $color-dark-input-placeholder;
        color: white;
      }
    }

    .MuiTableCell-root {
      border-top: 0.1rem solid #{$color-dark-background};
      border-bottom: 0.1rem solid #{$color-dark-background-secondary};
      color: $color-dark-text;
    }

    .MuiTableCell-head {
      background-color: $color-dark-background-secondary;
      .MuiTableSortLabel-root {
        &.MuiTableSortLabel-active,
        &:hover {
          color: $color-dark-text;
          .MuiTableSortLabel-icon {
            color: $color-dark-text;
          }
        }
      }
    }

    .MuiTableCell-body {
      color: $color-dark-text-secondary;
      background-color: $color-dark-border;
    }
  }

  //Pagination
  .MuiTablePagination-root {
    color: $color-dark-text;

    hr {
      background: $color-dark-background-secondary;
    }

    .MuiTablePagination-selectIcon,
    .MuiTablePagination-actions {
      color: $color-dark-text;

      .MuiIconButton-root {
        &.Mui-disabled {
          color: $color-dark-background-secondary;
        }
      }
    }
  }
}

.darkGreyTheme {
  //Table grid
  .MuiTable-root {
    background-color: $color-darkGrey-background2;

    .MuiTableRow-root {
      &:hover {
        .MuiTableCell-body {
          background: $color-darkGrey-background3 !important;
          color: white;
        }
      }
    }

    .rowSelected {
      &:hover {
        .MuiTableCell-body {
          background: $color-darkGrey-background4 !important;
          color: white;
        }
      }
      .MuiTableCell-body {
        background: $color-darkGrey-background3;
        color: white;
      }
    }

    .MuiTableCell-root {
      border-top: 0.1rem solid #{$color-darkGrey-background4};
      border-bottom: 0.1rem solid #{$color-darkGrey-background4};
      color: $color-darkGrey-text;
    }

    .MuiTableCell-head {
      background-color: $color-darkGrey-background4;
      .MuiTableSortLabel-root {
        &.MuiTableSortLabel-active,
        &:hover {
          color: $color-dark-text;
          .MuiTableSortLabel-icon {
            color: $color-dark-text;
          }
        }
      }
    }

    .MuiTableCell-body {
      color: $color-dark-text-secondary;
      background-color: $color-darkGrey-background2;

      a {
        color: $color-darkGrey-text;
      }
    }
  }

  //Pagination
  .MuiTablePagination-root {
    hr {
      background: $color-darkGrey-background4;
    }
    color: $color-darkGrey-text2;
    .MuiTablePagination-selectIcon,
    .MuiTablePagination-actions {
      color: $color-darkGrey-text2;

      .MuiIconButton-root {
        &.Mui-disabled {
          color: $color-darkGrey-background4;
        }
      }
    }
  }
}
