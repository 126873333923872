body {
  .modelInfoContainer {
    padding-top: 2rem;

    div {
      width: auto;
      max-width: none;
    }

    .tile {
      margin-right: 2rem;
    }
  }

  .retiredWarning {
    margin-top: 1rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    & > div {
      max-width: 55rem;
    }
  }
}
