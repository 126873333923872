@import '../../../../styles/variables';

.newChangesText {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  color: $color-darkGrey-text2;
}

.searchContainer {
  width: 40rem;
  margin-left: auto;
  margin-right: 20rem;
}
