@import '../../../styles/variables';

.sideBar {
  width: 24rem;
  overflow-x: hidden;
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  transition-delay: 0s;
  transition-duration: 0.195s;
  transition-property: width;
  transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1);

  &.toggleOpen {
    white-space: nowrap;
  }

  &:global(.left) :global(.MuiListItem-dense) {
    padding: 0;
  }

  &:global(.right) :global(.MuiListItem-dense) {
    padding: 0;
  }

  :global(.MuiList-padding) {
    padding-top: 0;
  }

  .buttonToggle {
    //margin-left: 0.8rem;
    padding: 0;
    width: 100%;
    height: 2.3rem;
    cursor: pointer;
  }

  :global(.MuiListItemIcon-root) {
    margin-left: 0rem;
    min-width: 3.2rem;
    height: 3.2rem;
    margin-right: 1.2rem;
    justify-content: center;
    align-items: center;
    color: currentColor;
  }

  &:global(.darkTheme) {
    border: 0.2rem solid #{$color-dark-border};
    background: $color-dark-background;
    color: $color-dark-text;
    hr {
      background-color: $color-dark-border;
    }
    .buttonToggle {
      background-color: $color-dark-border;
    }
  }

  &:global(.whiteTheme),
  &:global(.greyTheme) {
    background: $color-white;
    border-color: $color-light-border;
    color: $color-light-text;
    .buttonToggle {
      background-color: $color-light-background;
    }
  }
}
