.chart {
  margin-bottom: 3rem;
  page-break-inside: avoid;
  p,
  text,
  button {
    font-family: 'Invention', serif !important;
  }
  tr,
  button {
    font-size: 1.3rem;
  }
  li > button {
    width: max-content;
  }
  //tabular representation, font-size of the chart name
  p {
    font-size: 1.8rem;
  }
  :global(#modal-title) {
    font-size: 1.2rem;
  }
  :global(.bx--modal-close) {
    width: 4rem;
    height: 4rem;
  }
  :global(p.title) {
    font-size: 1.6rem;
  }
}
