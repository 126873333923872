@import '../../../styles/variables';

$timingFn: 150ms cubic-bezier(0.2, 0, 0.38, 0.9);

body {
  .columnExpandable {
    width: 3rem;
  }

  .headerLabel {
    display: flex;
    font-weight: 600;
  }

  .row {
    user-select: none;
  }

  .emptyRow td {
    opacity: 0.7;
  }

  .rowExpandable {
    .rowExpandableButton {
      transition: transform #{$timingFn};
    }

    + .rowExpansion {
      transition: height #{$timingFn};

      td {
        border: none;
        transition: all #{$timingFn};
      }
    }

    &.rowNotExpanded {
      .rowExpandableButton {
        transform: rotate(-90deg);
      }

      + .rowExpansion {
        height: 0;

        td {
          padding-top: 0;
          padding-bottom: 0;
          height: 0;
          overflow: hidden;

          > div {
            max-height: 0%;
            height: 0;
            overflow: hidden;
          }
        }
      }
    }

    &.rowExpanded {
      .rowExpandableButton {
        transform: rotate(90deg);
      }

      + .rowExpansion {
        height: 5rem;

        > td {
          border-bottom: 0.1rem solid #{$color-light-border}; //FIXME proper color based on theme
          > div {
            max-height: 100%;
          }
        }
      }
    }
  }

  //external model styles
  .icon {
    position: absolute;
    left: -2.6rem;
  }
  .externalTable {
    //padding-left: 3rem;
    display: inline-block;
    background-color: inherit;
    th {
      font-weight: 600;
      height: 3.6rem;
    }
  }
  .externalTRow {
    height: 3.6rem;
    position: relative;
    background-color: #f3f3f3;
    &:last-child {
      td:first-child:before {
        border: 0.1rem solid #0fa3ff;
        border: unset;
      }
    }
    td:first-child {
      &:after {
        position: absolute;
        top: -1px;
        left: -3.75rem;
        display: block;
        height: 2.3rem;
        width: 0.7rem;
        border-bottom: 0.1rem solid #777;
        border-left: 0.1rem solid #777;
        content: '';
      }
      &:before {
        position: absolute;
        top: -4rem;
        bottom: 0;
        left: -3.75rem;
        display: block;
        width: 0;
        border-left: 0.1rem solid #777;
        content: '';
      }
    }
  }

  :global(.lightweightTheme) table {
    background-color: transparent !important;

    thead th {
      background-color: $color-white !important;
      font-size: 1.2rem;
      border: none;
    }
  }
}
