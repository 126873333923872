$triangleSize: '4px';
$arrowColor: red;

.container {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 20px;
  height: 100%;
}

.line {
  position: absolute;
  left: 50%;
  height: 100%;
  width: 1px;
  background-color: $arrowColor;
}

.arrowDown {
  position: absolute;
  left: 50%;
  margin-left: -#{$triangleSize};
  bottom: 0;

  width: 0;
  height: 0;
  border-left: #{$triangleSize} solid transparent;
  border-right: #{$triangleSize} solid transparent;

  border-top: #{$triangleSize} solid #{$arrowColor};
}
