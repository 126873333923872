.driver-popover {
  background-color: #ffffff;
  color: #000000;
  min-width: 250px;
  min-height: 150px;
  border-radius: 0;
}

.driver-popover .driver-popover-title {
  font-size: 20px;
  color: #000000;
}
.driver-popover .driver-popover-description {
  color: rgb(56, 57, 58);
  margin-bottom: 30px;
}

.driver-popover-footer {
  display: flex;
  flex-direction: column;
}
.driver-popover-navigation-btns {
  order: 1;
}
.driver-popover-progress-text {
  order: 2;
  margin-left: -90%;
}
.driver-popover .driver-popover-progress-text {
  color: #000000;
}

.driver-popover span button {
  background-color: #0062ff;
  color: #ffffff;
  text-shadow: none;
  font-size: 14px;
  padding: 10px 10px;
  margin: 12px 12px;
  border-radius: 0;
  order: 1;
  width: 115px;
  text-align: center;
}

.driver-popover span button:hover {
  background-color: #00008b;
}

.driver-popover .driver-popover-navigation-btns {
  justify-content: space-between;
  gap: 3px;
}

.driver-popover .driver-popover-close-btn {
  color: #393939;
  font-size: 40px;
  padding-right: 10px;
  font-weight: 300;
}

.driver-popover .driver-popover-close-btn:hover {
  color: #000000;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-left.driver-popover-arrow {
  border-left-color: #ffffff;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-right.driver-popover-arrow {
  border-right-color: #ffffff;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-top.driver-popover-arrow {
  border-top-color: #ffffff;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-bottom.driver-popover-arrow {
  border-bottom-color: #ffffff;
}
