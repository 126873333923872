@import '~@mds/merck-design-system/common/colorPalette/colors';
@import '~@mds/merck-design-system/common/scale';
@import '~@mds/merck-design-system/common/shadows';
@import '~@mds/merck-design-system/common/spacing';
@import '~@mds/merck-design-system/common/font/fontFace';
@import '~@mds/merck-design-system/common/font/fontFamilies';
@import '~@mds/merck-design-system/common/font/fontSizes';
@import '~@mds/merck-design-system/common/font/fontWeights';

$primary-color: #0062ff;
$secondary-color: $color-cyan-100;

$success-color: $color-green-400;
$error-color: $color-red-400;
$warning-color: $color-orange-400;
$info-color: $color-indigo-200;

:export {
  primary: $primary-color;
  secondary: $secondary-color;
  white: $color-white;
}
$color-dark-input: #bebebe;
$color-dark-input-placeholder: #8f8f8f;
$color-dark-input-background: #393939;

$color-dark-background: #171717;
$color-dark-background-secondary: #3d3d3d;
$color-dark-background-header: #2d2d2d;
$color-dark-border: #2d2d2d;
$color-dark-text: #f3f3f3;
$color-dark-text-secondary: #bebebe;
$color-dark-label: #dcdcdc;

$color-light-background: #f3f3f3; // closest is $color-grey-50;
$color-light-border: #dcdcdc; //closest is $color-grey-200;
$color-light-placeholder: #8c8c8c;
$color-light-text: #171717;
$color-light-text-secondary: #bebebe;
$color-light-text-label: #565656;

$header-height: 4.8rem;

$color-darkGrey-background: #2d2d2d;
$color-darkGrey-background2: #363636; //rgb(53,53,53);
$color-darkGrey-background3: #434343; //rgb(67,67,67);
$color-darkGrey-background4: #4c4c4c; //rgb(76,76,76);
$color-darkGrey-text: #f3f3f3;
$color-darkGrey-text2: #bebebe;
$color-darkGrey-input-placeholder: #6f6f6f;

$color-darkBlue-background: #0c2340;
$color-darkBlue-background2: lighten($color-darkBlue-background, 10%);
$color-darkBlue-background3: lighten($color-darkBlue-background, 20%);
$color-darkBlue-background4: lighten($color-darkBlue-background, 30%);
$color-darkBlue-text: #ffffff;
$color-darkBlue-text2: #f3f3f3;
$color-darkBlue-input-placeholder: lighten($color-darkBlue-background, 80%);

$color-blueText1: #273472;
$color-blueText2: #366fff;
