@import './variables';
@import './remFix';
@import './print';
@import './themed/pageHeader';
@import './themed/defaultPage';
@import './themed/editor';
@import './themed/grid';
@import './themed/card';
@import './themed/form';
@import './themed/buttons';
@import './themed/tabs';
@import './themed/fileTree';

body {
  background-color: $color-white !important;

  .MuiTable-root {
    .rowSelected {
      background: $color-indigo-100;
      &:hover {
        background: $color-indigo-300 !important;
      }
      .MuiTableCell-body {
        color: white;
      }
    }

    background-color: $color-light-background;
    .MuiTableCell-head {
      background-color: $color-light-border;
    }
  }

  // hide native input clear button in IE10(9?)+ and Edge
  ::-ms-clear {
    display: none;
  }

  .MuiButtonBase-root {
    flex-shrink: 0;
  }

  .MuiButton-root {
    text-transform: none;

    &.noBoxShadow {
      box-shadow: none;
    }

    &.whiteButton {
      background-color: $color-white;
      color: $color-light-text;

      &.Mui-disabled {
        background-color: $color-light-border;
        color: $color-light-text-secondary;
      }

      &:hover {
        background-color: $color-light-border;
      }
    }

    &.whiteSmokeButton {
      background-color: $color-grey-50;
      color: $color-blueText2;
      border: $color-blueText2 1px solid;

      &.Mui-disabled {
        background-color: $color-light-border;
        color: $color-light-text-secondary;
      }

      &:hover {
        background-color: $color-light-border;
      }
    }

    &.redButton {
      &.MuiButton-outlined {
        color: $color-red-400;
        border-color: $color-red-400;
        &:hover {
          color: $color-red-600;
          border-color: $color-red-600;
          background-color: rgba(255, 98, 0, 0.08);
        }
      }

      &.MuiButton-contained {
        background-color: $color-red-400;
        color: $color-white;

        &:hover {
          background-color: $color-red-600;
        }

        &.Mui-disabled {
          background-color: $color-red-50;
        }
      }
    }

    &.inverseColor {
      background-color: $color-dark-background;
      color: $color-dark-text;

      &:hover {
        background-color: $color-dark-border;
      }
    }
  }

  .MuiTab-root {
    text-transform: none;
  }

  .darkTheme {
    //button
    .MuiButton-root.MuiButton-text,
    .MuiIconButton-root {
      color: $color-dark-text;
    }
  }

  .noTextTransform {
    text-transform: none;
  }

  .icon32 {
    width: 3.2rem;
    height: 3.2rem;
  }

  .icon16 {
    width: 1.6rem;
    height: 1.6rem;
  }

  .headline {
    margin: 2rem 0;
    font-size: 2rem;
    max-width: 66rem;
  }
  .headlineBlue {
    @extend .headline;
    color: $primary-color;
  }
  .headlineRed {
    @extend .headline;
    color: $error-color;
  }

  .wrapRelative {
    position: relative;
  }

  .centerFlex {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-grow: 1;
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  .colorError {
    color: $error-color;
  }
  .colorWarning {
    color: $warning-color;
  }
  .colorInfo {
    color: $info-color;
  }
  .color.Success {
    color: $success-color;
  }
}
