@import '../../../../../styles/variables';

$shared-width: 23rem;

.container {
  max-width: 66rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: calc(100vw - 20rem);

  label {
    margin-bottom: 1rem !important;
    //display: inline-block;
    width: $shared-width;
  }

  input[type='file'] {
    display: none;
  }

  &.bitbucket {
    background: $color-darkBlue-background;
    box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.5);

    .title,
    .dropInfo {
      color: white;
    }
  }

  &.mlmp {
    .panelBitbucket {
      display: none;
    }

    .panelMlmp {
      width: 100%;
      background: $color-white;
      box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.5);
    }
  }

  &.none {
    .panelBitbucket {
      background: $color-darkBlue-background;
      box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.5);

      .title {
        color: white;
      }
    }

    .panelMlmp {
      background: $color-white;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
    }
  }
}

.fileTreePanel {
  padding: 3rem;
  display: flex;
  flex-direction: column;
}
.panel {
  width: 39rem;
  padding: 3rem;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 2rem;
    color: $primary-color;
    margin-bottom: 2rem;
  }
}

.dropInfo {
  font-size: 1.4rem;
  margin-top: 2rem;
}

.dropZone {
  display: block;
  border: 0.2rem dashed #ccc;
  padding: 1rem;
  color: $primary-color;
  font-size: 1.4rem;
  min-height: 10rem;
  margin: 2rem 0;
  width: $shared-width;
}

.dropDisabled {
  opacity: 0.5 !important;
}

.stepContainer {
  display: inline-block;
  margin-right: auto;
  margin-bottom: 2rem;
}

.stepper {
  max-width: 30rem;
  margin: auto;
}

.userCardContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
}

.userCard {
  flex-grow: 1;
}
