.cardWrapper {
  margin-bottom: 0.2rem;
  zoom: 0.95;
  margin-left: 5%;
  position: relative;
  box-shadow: 0rem 2rem 1rem -1rem rgba(0, 0, 0, 0.2), 0rem 1rem 1rem 0rem rgba(0, 0, 0, 0.18),
    0rem 1rem 3rem 0rem rgba(0, 0, 0, 0.24);

  &:first-child {
    zoom: 1;
    margin-left: 0;
  }

  @media only screen and (max-width: 959px) {
    & > div {
      width: 100%;
    }
  }
}
