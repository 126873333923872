.container {
  padding: 2rem 0rem;
  position: relative;

  .icon {
    position: absolute;
    left: -4.2rem;
    top: 2rem;
  }
}
