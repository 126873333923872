@import '../../../../../../styles/variables';

.relativeWrapper {
  position: relative;
  z-index: 1;
}

.contentContainer {
  max-width: 85rem;
  padding: 0 1rem;
  margin: auto;
}
.warningContainer {
  margin-top: 4rem;
  margin-bottom: 12rem;
}

.modelWrapper {
  min-height: 16rem;
}

.tabsContainer {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 2rem;
  top: 1rem;

  @media only screen and (max-width: 959px) {
    position: relative;
    margin-bottom: 2rem;
  }
}

.zoomContainer {
  position: fixed;
  left: 6rem;
  background-color: $color-white;
  z-index: 100;

  :global(.MuiListItemIcon-root) {
    min-width: 0;
    padding: 0 1rem;
  }
}

.topButtonsContainer {
  position: absolute;
  right: 2rem;
  top: 1rem;
  height: 8rem;
  padding-left: 8px;

  button,
  a {
    margin-left: 1rem;
    svg {
      margin-left: 3rem;
    }
  }

  @media only screen and (max-width: 1350px) {
    position: relative;
    //max-width: 85rem;
    //margin: auto;
    text-align: right;
  }
}

.buttonsContainer {
  margin: 0 2rem 2rem auto;
  text-align: right;
  button,
  a {
    margin-left: 1rem;
  }
}

.spacer {
  height: 10rem;
}

body {
  .selectButton {
    margin-left: auto;
    margin-bottom: 1rem;
    svg {
      margin-left: 8rem;
    }
  }
  .gridItem {
    margin-top: 0;
    margin-bottom: 2rem;
    text-align: right;
  }
  .gridItemButtons {
    @extend .gridItem;
    text-align: right;
  }
}
