@import '../../../../styles/variables';

$offsetLeft: '1rem';

.container {
  height: calc(100% - 7rem);
  max-height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.pageContainer {
  position: relative;
  width: 100%;
  //min-height: 100%;
  padding-top: 4rem;
  padding-left: 4rem;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
}

//.svgContainer {
//  position: absolute;
//  left: 0;
//  top: 0;
//  width: 100%;
//  height: 100%;
//}

.modelColumnContainer {
  display: flex;
  flex-direction: column;
  max-width: 49rem;
  min-width: 23%;
  position: relative;
  //margin-left: 4rem;
  //margin-top: 4rem;
}

.searchContainer {
  width: 22rem;
  padding: 1rem 2rem;
  position: relative;
}

.subModelList {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: $color-light-background;
  margin-left: 1rem;
  //padding-top: 1rem;
  //padding-left: 1rem;

  :global(.fileTree) {
    ol {
      padding-left: 1.5rem;
    }
    li {
      padding-top: 1rem;
      &:before {
        border-left: 1px solid #8d8d8d;
        margin-top: -1px;

        left: -#{$offsetLeft};
      }
    }

    :global(.treeNode) {
      padding-left: 0;
      &:after {
        left: -#{$offsetLeft};
        width: #{$offsetLeft};
        height: 50%;
        border-bottom: 1px solid #8d8d8d;
        border-left: 1px solid #8d8d8d;
        border-bottom-left-radius: 7px;
      }
    }
  }

  :global(.cardNode) {
    width: 22rem;
    //margin-bottom: 1rem;
    cursor: pointer;
  }

  :global(.folderNode) {
    //margin-bottom: 1rem;
    //margin-left: 2rem;
    width: calc(100% - 3rem);

    :global(.column) {
      display: flex;
    }

    :global(.column):nth-of-type(2) {
      overflow: hidden;

      & > div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

:global(.cardNode) {
  min-height: 6.5rem;
}

.scenarioContainer {
  //margin-left: 40px;
  margin-bottom: 8rem;

  .searchContainer {
    padding-left: 0;
    padding-top: 0;

    :global(.MuiTextField-root) {
      margin-top: 0;
    }
  }
}

.groupTitle {
  font-size: 1.8rem;
  font-weight: 600;
  color: #0c2340;
}
