@import '../../../styles/variables';

body {
  .title {
    display: flex;
    align-items: center;
    font-size: 1.4rem;

    &:hover {
      text-decoration: none;
    }

    .logo {
      width: 2.8rem;
      height: 2.8rem;
      margin: 0 1.4rem 0 1.4rem;
    }
  }

  .navButton {
    text-transform: none;
    font-size: inherit;
  }
  .indicator {
    height: 0.4rem;
  }
}
.notificationsMenu {
  background: #4c4c4c;
  color: #fff;
  width: 400px;
  min-height: 80px;

  .notificationsHeader {
    padding: 15px;
    .rightElement {
      color: grey;
      float: right;
    }
    .clearAll {
      color: #0062ff;
      cursor: pointer;
      float: right;
    }
  }
  .notificationsContainer {
    border-top: 2px solid grey;
  }
  .notificationItem {
    padding: 15px;
    min-height: 20px;
    border-top: 2px solid grey;
  }
  .leftPane {
    display: table-cell;
    width: 340px;
  }
  .rightPane {
    display: table-cell;
    vertical-align: middle;
    width: 30px;
    cursor: pointer;
  }
  .closeButton {
    color: #0062ff;
  }
  .notificationsFooter {
    padding: 15px;
    color: grey;
  }
  .showAllButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 2px solid grey;
    padding: 15px;
    cursor: pointer;
    color: #0062ff;
  }
  .showAllGreyButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 2px solid grey;
    padding: 15px;
    color: grey;
  }
}

button {
  &.userMenuButton,
  &.helpMenuButton,
  &.notificationsButton {
    color: $color-white;
    text-transform: none;
  }
  &.notificationsButtonSelected {
    background-color: #4c4c4c;
    color: $color-white;
    text-transform: none;
  }
}
.notificationsButton * {
  box-sizing: content-box !important;
  word-break: break-word;
  word-wrap: break-word;
}
.notificationIdentifier {
  position: absolute !important;
  border: 4px solid #b10b02 !important;
  border-radius: 50% !important;
  bottom: 28px !important;
  right: 28px !important;
  background: #b10b02 !important;
}
