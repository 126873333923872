@import '../../../styles/variables';

body {
  .form {
    margin: 0 auto;
    max-width: 60rem;

    > * {
      margin-bottom: 2rem;
    }
  }
}
