@import '../../../../../styles/variables';

.toggleDiffButton {
  &.current :global(.MuiTypography-body2) {
    font-weight: bold;
  }
  &.selected {
    color: $primary-color;
  }

  :global(.MuiTypography-body2) {
    padding-left: 1rem;
    font-size: 1.2rem;
  }
}

.toggleDiffButtonSpacer {
  height: 0.5rem;
}

.modelActionMenu {
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #f7f7f7;
    border-top: 1px solid #{$color-light-border};
    border-left: 6px solid transparent;
    height: 4.4rem;
    flex-wrap: nowrap;
    overflow: hidden;
    margin-top: 1rem;
    margin-bottom: 1rem;

    & > svg {
      flex-shrink: 0;
      margin-left: 0.6rem;
      margin-right: 2.1rem;
    }
  }

  hr {
    margin: 1rem 0;
  }
}
