@mixin secondaryTabsTheme(
  $background,
  $color,
  $backgroundSelected,
  $colorSelected,
  $backgroundDisabled,
  $colorDisabled,
  $backgroundHover
) {
  .tabs-secondary {
    background-color: $background;
    color: $color;
    border-radius: 0.4rem;
    min-height: 4rem;

    button {
      transition: color 250ms;
      min-height: 4rem;
      min-width: 13rem;

      &.redTab {
        color: #e45050;
      }
      &.greenTab {
        color: #6eceb2;
      }

      &.Mui-selected {
        z-index: 2;
        color: $colorSelected;
        //background-color: $color-dark-background;
      }

      &.Mui-disabled {
        background-color: $backgroundDisabled;
        color: $colorDisabled;
        opacity: 1;
      }

      &:not(.Mui-selected):not(.Mui-disabled):hover {
        background: $backgroundHover;
      }

      .MuiTab-wrapper {
        align-items: flex-start;
        //padding-left: 1rem;
      }
    }

    .MuiTabs-indicator {
      height: 100%;
      background: $backgroundSelected;
      z-index: 1;
    }

    &.small {
      min-height: 2rem;
      button {
        min-height: 2rem;
      }
    }
  }
  .tabs-incident {
    background-color: #171717;
    color: $color;
    border-radius: 0.4rem;
    min-height: 4rem;

    button {
      transition: color 250ms;
      min-height: 4rem;
      min-width: 13rem;

      &.redTab {
        color: #e45050;
      }
      &.greenTab {
        color: #6eceb2;
      }

      &.Mui-selected {
        z-index: 2;
        color: $colorSelected;
        //background-color: $color-dark-background;
      }

      &.Mui-disabled {
        background-color: $backgroundDisabled;
        color: $colorDisabled;
        opacity: 1;
      }

      &:not(.Mui-selected):not(.Mui-disabled):hover {
        background: $backgroundHover;
      }

      .MuiTab-wrapper {
        align-items: flex-start;
        //padding-left: 1rem;
      }
    }

    .MuiTabs-indicator {
      height: 100%;
      background: $backgroundSelected;
      z-index: 1;
    }

    &.small {
      min-height: 2rem;
      button {
        min-height: 2rem;
      }
    }
  }
}

body {
  .whiteTheme {
    @include secondaryTabsTheme(
      $color-light-background,
      $color-dark-background,
      $color-dark-background,
      $color-white,
      $color-light-border,
      $color-light-text-secondary,
      $color-light-border
    );
  }

  .greyTheme {
    @include secondaryTabsTheme(
      $color-white,
      $color-dark-background,
      $color-dark-background,
      $color-white,
      $color-light-border,
      $color-light-text-secondary,
      $color-light-background
    );
  }

  .darkGreyTheme {
    @include secondaryTabsTheme(
      $color-dark-background,
      $color-dark-text,
      $color-dark-text,
      $color-dark-background,
      $color-dark-background-secondary,
      $color-dark-text-secondary,
      $color-dark-background
    );
  }

  .darkTheme {
    @include secondaryTabsTheme(
      $color-dark-background-secondary,
      $color-dark-text,
      $color-dark-text,
      $color-dark-background,
      $color-dark-background-secondary,
      $color-dark-text-secondary,
      $color-dark-background-secondary
    );
  }
}
