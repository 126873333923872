.contentContainer {
  max-width: 85rem;
  padding: 0 1rem;
  margin: auto;
}

.warningContainer {
  margin-top: 4rem;
  margin-bottom: 12rem;
}

.topButtonsContainer {
  position: absolute;
  right: 2rem;
  top: 1rem;
  height: 8rem;
  padding-left: 8px;

  button,
  a {
    margin-left: 1rem;
    svg {
      margin-left: 3rem;
    }
  }

  @media only screen and (max-width: 1050px) {
    position: relative;
    text-align: right;
  }
}

.buttonsContainer {
  margin: 0rem 2rem 2rem auto;
  text-align: right;
  button,
  a {
    margin-left: 1rem;
  }
}

body {
  .formWrapper {
    margin-top: 5rem;
    margin-left: 1rem;
  }

  .notes {
    :global(.MuiInputLabel-root) {
      width: 125%;
    }
    :global(.MuiTextField-root) {
      margin-top: 3.4rem;
    }
  }
}
