body {
  .navBar {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 4rem;
    margin-top: 3rem;
    margin-left: 5rem;
  }
}
