body {
  .title {
    font-weight: bold;
    font-size: 1.3rem;
  }
  .value {
    font-size: 1.3rem;
  }
  .underlined {
    font-size: 1.3rem;
    text-decoration: underline;
  }
}
