.container {
  text-align: center;
}

.generatedNameLabel {
  font-size: 12px;
  color: #525252;
  letter-spacing: 0.32px;
  line-height: 16px;
}

.generatedName {
  display: inline-block;
  text-align: left;
  font-size: 2.6rem;
  color: #0c2340;
  min-width: 30rem;
}

.plusSign {
  font-size: 2.6rem;
  color: #0c2340;
}

.inputContainer {
  width: 29rem;
  margin: 1rem auto 0;

  label {
    width: 100%;
    text-align: left;
  }
}
