.listItemBigButton:global(.MuiListItem-root) {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  padding: 2rem 0;

  :global(.MuiListItemText-root) {
    text-align: center;
    font-size: 1.4rem;
  }
}
