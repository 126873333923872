@import '../../../../../styles/variables';

body {
  .expandCollapseButton {
    min-height: 4rem;
    margin: 0 0 0 auto;
  }

  .navBar {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 4rem;
    margin-top: 2rem;
  }

  .requestAccessText {
    color: $primary-color;
    margin: 2rem 0;
    font-size: 2rem;
  }
}
