.container {
  height: calc(100% - 7rem);
  max-height: 100%;
  overflow: auto;
}

.contentContainer {
  max-width: 100rem;
  margin: 0 auto;
  padding: 5rem;

  :global(.buttonsContainer) {
    max-width: 100rem;
    padding: 0 5rem;
  }
}

.parametersWrapper {
  td > :global(.MuiFormControl-fullWidth) {
    min-width: 14rem;
  }
}
