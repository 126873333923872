.wrapper {
  //border: 1px solid black;
  display: inline-block;
  min-width: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.table {
  border-collapse: collapse;
  min-width: 100%;
  //border-spacing: 0;

  thead {
    td,
    th {
      background-color: #dcdcdc;
      text-align: center;
      font-weight: bold;
      color: black;
      vertical-align: baseline;
    }
    th {
      font-weight: bold;
    }
  }

  tbody tr {
    background-color: #f3f3f3;
    border-top: 1px solid #dcdcdc;
  }

  td,
  th {
    font-weight: normal;
    padding: 0 4px;
    color: #565656;

    &:global(.leftPartCell) {
      border-right: 0;
      padding-right: 0;
      text-align: right;
    }
    &:global(.rightPartCell) {
      text-align: left;
      border-left: 0;
      padding-left: 0;
      width: 0;
    }
  }

  //tr:nth-child(even) {
  //  background-color: #dcdcdc;
  //}
  th {
    text-align: left;
  }
  td {
    font-size: 1.2rem;
    padding: 0 4px;
  }
}

.footNotesContainer {
  margin-top: 5px;
  p {
    margin: 0;
    font-size: 1.2rem;
  }
}

.captionContainer {
  margin-top: 5px;
  p {
    font-size: 1.4rem;
  }
}
