@import '../variables';

@mixin formTheme($background, $color, $placeholderColor, $border, $backgroundDisabled, $colorDisabled) {
  //input search
  .MuiFilledInput-root,
  .MuiSelect-root {
    background-color: $background;
    color: $color;
    transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    &.Mui-disabled {
      background-color: $backgroundDisabled;
      color: $colorDisabled;
    }

    input::placeholder {
      color: $placeholderColor;
    }

    border-bottom: 0.1rem solid #{$border};
  }

  .MuiCheckbox-colorSecondary,
  .MuiRadio-colorSecondary {
    color: $color;
  }

  //input
  .MuiFormControl-root {
    label {
      color: $color;
    }

    .MuiButtonBase-root,
    .MuiSvgIcon-root {
      color: $color;
    }

    .MuiSwitch-root {
      &.noLabel {
        margin-top: 1rem;
        margin-left: 0;

        & ~ .MuiFormControlLabel-label {
          margin-top: 1rem;
        }
      }

      &.withLabel {
        margin-top: 2.2rem;
        //margin-left: -1rem;

        & + .onOffLabel {
          position: relative;
          top: 11px;
        }
      }

      .MuiSwitch-colorPrimary {
        color: $primary-color;
        &.Mui-disabled {
          color: $backgroundDisabled;
        }
        .onOffLabel {
          margin-top: 5px;
        }
      }
    }

    //.MuiCheckbox-root {
    //  &.checkboxTop {
    //    padding-top: 0 !important;
    //  }
    //}
  }
}

body {
  .MuiFormLabel-asterisk {
    color: $error-color;
    font-size: 2.5rem;
    position: absolute;
    right: -1.5rem;
    top: -0.5rem;

    //&:before {
    //  visibility: visible;
    //  position: absolute;
    //  left: -16px;
    //  top: 18px;
    //  color: $error-color;
    //  content: '\25A0';
    //  font-size: 2.5rem;
    //}
  }

  .checkboxTop {
    padding-top: 0 !important;
  }

  .buttonsContainer {
    text-align: right;
    margin-bottom: 2rem;

    button:nth-of-type(2) {
      margin-left: 1rem;
    }

    &.alignLeft {
      text-align: left;
      > * {
        margin-left: 0rem;
        margin-right: 1rem;
      }
    }
    &.alignCenter {
      text-align: center;
      > * {
        margin-left: 0rem;
        margin-right: 1rem;
      }
    }
  }

  .submitButton {
    min-width: 17rem;
    justify-content: space-between;
    svg.MuiSvgIcon-root {
      margin-left: 3rem;
    }
  }

  .MuiAutocomplete-popperDisablePortal {
    margin-top: -2rem;
  }

  .disableNoOptionsForEmptySearch.emptySearch.Mui-focused + .MuiAutocomplete-popperDisablePortal {
    display: none;
  }

  .emptySearch.emptyValue .MuiAutocomplete-clearIndicator {
    display: none;
  }

  .MuiFormControl-hem {
    //.MuiFormLabel-root.Mui-focused {
    //  color: rgba(0, 0, 0, 0.54);
    //}

    .MuiTextField-root {
      margin-top: 2.2rem;
    }

    .MuiInputBase-multiline.MuiInputBase-marginDense {
      padding-top: 0.3rem;
    }

    .MuiFormHelperText-root {
      margin-left: 0;
    }

    .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] {
      padding-top: 0.5rem;
      padding-bottom: 0.6rem;
    }

    .MuiAutocomplete-popperDisablePortal {
      margin-top: 5.7rem;
    }
  }

  .whiteTheme,
  .greyTheme .whiteTheme {
    @include formTheme(
      $color-light-background,
      $color-light-text,
      $color-light-text,
      $color-light-border,
      $color-light-text-secondary,
      $color-light-text
    );
  }

  .greyTheme {
    @include formTheme(
      $color-white,
      $color-light-text,
      $color-light-text,
      $color-light-border,
      $color-light-border,
      $color-light-text
    );
  }

  .darkGreyTheme {
    @include formTheme(
      $color-darkGrey-background2,
      $color-darkGrey-text2,
      $color-darkGrey-text2,
      $color-darkGrey-background4,
      $color-darkGrey-background,
      $color-darkGrey-text2
    );
  }

  .darkTheme {
    @include formTheme(
      $color-dark-input-background,
      $color-dark-input,
      $color-dark-input,
      $color-dark-input-placeholder,
      $color-darkGrey-background,
      $color-darkGrey-text2
    );
  }

  .darkBlueTheme {
    @include formTheme(
      $color-darkBlue-background3,
      $color-darkBlue-text2,
      $color-darkBlue-input-placeholder,
      $color-darkBlue-background4,
      $color-darkBlue-background2,
      $color-darkBlue-text2
    );
  }
}
