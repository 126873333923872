@import './../../../../styles/variables';

.cardNode {
  display: flex;
  position: relative;
  background-color: $color-white;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 1rem 0.5rem;
  border: 0.25rem solid transparent;
  border-left: 0.25rem solid #161616;

  &.fullBorder {
    border: 0.25rem solid #161616;
  }

  &:global(.greyTheme) {
    background-color: $color-light-background;
  }

  :global(.column) {
    padding: 0 0.5rem;
    display: block;
    margin-bottom: auto;

    &:global(.farsideColumn) {
      margin-left: auto;
    }
  }

  :global(.title) {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.29;
    letter-spacing: 0.16px;
    margin: 0;
  }

  :global(.subtitle) {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.29;
    letter-spacing: 0.16px;
    margin: 0;
  }

  :global(.label) {
    display: block;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.34;
    letter-spacing: 0.32px;
    color: #525252;
    padding-top: 1.5rem;
  }
}
