@import '../../../../styles/variables';

.tableContainer {
  overflow: hidden;
}

.contentContainer {
  max-width: 100rem;
  margin: 2rem auto;

  .executionError {
    color: $error-color;

    .executionErrorText {
      padding-left: 1.6rem;
    }
  }

  .editorContainer {
    margin: 2rem 1.6rem;
    position: relative;

    :global(.ace_editor) {
      min-height: 60rem;
    }

    :global(.ace_content) {
      font-size: 1.3rem;
    }

    :global(.jsoneditor-menu) {
      display: none;
    }

    //:global(.jsoneditor-statusbar) {
    //  border-top: $color-darkGrey-background4;
    //  background: $color-darkGrey-background2;
    //  color: $color-darkGrey-text2;
    //}
  }
}
.sectionHeader {
  display: flex;
  :global(.headlineBlue) {
    display: none;
  }
}
.dialog {
  :global(.MuiDialogContent-root) {
    padding-left: 0;
    padding-right: 0;
  }
  :global(.MuiPaper-root) {
    background: #f3f3f3;
  }
}

.expandCollapseButton {
  padding-top: 1.8rem;
  position: absolute;
  right: 0;
}
