@use "sass:math";
$spinner-size: 2rem;

:export {
  spinnerSize: $spinner-size;
}

.wrapper {
  position: relative;
}

.progressWrapper {
  position: absolute;
  top: calc(50% - #{(math.div($spinner-size, 2))});
  width: 100%;
  text-align: center;
}
