@use "sass:math";
// hack carbon icons in order to zoom works
@mixin svgPxToRem($dimension: number) {
  $remDimension: math.div($dimension, 10);

  svg[width='#{$dimension}'][height='#{$dimension}'] {
    width: #{$remDimension}rem;
    height: #{$remDimension}rem;
  }
}

body {
  @include svgPxToRem(16);
  @include svgPxToRem(20);
  @include svgPxToRem(32);

  .MuiList-padding {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .MuiCardHeader-root {
    padding: 1rem;
  }

  .MuiTabs-root {
    min-height: 4.8rem;
  }

  .MuiTab-root {
    min-width: 16rem;
    max-width: 26.4rem;
    min-height: 4.8rem;
    padding: 0.3rem 0.6rem;

    @media only screen and (max-width: 1100px) {
      min-width: 12rem;
    }
  }

  .MuiStepper-root {
    padding: 2rem;
  }

  .MuiTypography-body2 {
    font-size: 1.4rem;
  }

  .MuiCardHeader-avatar {
    margin-right: 1rem;
  }

  .MuiCardHeader-action {
    margin-top: -0.5rem;
    margin-right: -0.5rem;
  }

  .MuiTableCell-sizeSmall {
    padding: 0.375rem 1.5rem 0.375rem 1rem;
  }

  .MuiAutocomplete-listbox {
    padding: 0.5rem 0;
  }

  .MuiDialogContent-root {
    padding: 0.8rem 2.4rem;
  }

  .MuiDialogTitle-root {
    padding: 1.6rem 2.4rem;
  }

  .MuiDialogActions-root {
    padding: 0.8rem 2.4rem 1.6rem;
  }
}
