@import '../../../styles/variables';

.container {
  display: flex;
  flex-direction: row;
}

.step {
  border-top: 3px solid #8f8f8f;
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;

  svg {
    margin-top: 2px;
    color: $primary-color;
  }

  &.disabled {
    cursor: not-allowed;
    svg {
      color: $color-dark-input-placeholder;
    }
  }

  &.completed {
    border-top-color: #{$primary-color};
  }

  &.warning {
    border-top-color: #{$warning-color};
  }

  &.error {
    border-top-color: #{$error-color};
  }

  &.active {
    border-top-color: #{$primary-color};
  }
}

.icon {
  margin-right: 8px;
}

.labelContainer {
  display: flex;
  flex-direction: column;
}

:global(.whiteTheme),
:global(.greyTheme) {
  .stepTitle {
    color: $color-light-text;
  }

  .disabled {
    .stepTitle {
      color: $color-light-text-secondary;
    }
  }

  .stepLabel {
    color: $color-light-text-secondary;
  }
}

:global(.darkTheme),
:global(.darkGreyTheme) {
  .stepTitle {
    color: $color-darkGrey-text;
  }

  .stepLabel {
    color: $color-darkGrey-text2;
  }
}
