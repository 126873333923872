@import '../../../../../styles/variables';

.container {
  max-width: 66rem;
  margin: 4rem auto;

  :global(.card) {
    margin-right: 2rem;
    box-shadow: 0 2rem 2rem rgba(0, 0, 0, 0.3);
  }

  :global(.headlineBlue) {
    margin-bottom: 0;
  }

  td {
    vertical-align: top;
  }
}

.compareCardContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  :global(.card) {
    margin: 2rem;
    box-shadow: 0 2rem 2rem rgba(0, 0, 0, 0.3);
  }
}

.title {
  font-size: 2rem;
  line-height: 2rem;
  color: $primary-color;
}
.subtitle {
  font-size: 1rem;
}
