@import '../../../../styles/variables';

.container {
  box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 1;
  width: 12rem;
}

.showOnly {
  display: inline-block;
  font-size: 1.4rem;
  color: $color-light-text-secondary;
  margin: 3rem auto 1rem;
}
