@import '../../../../styles/variables';

$footer-height: 7rem;

.footer {
  width: 100%;
  height: $footer-height;
  position: absolute;
  left: 0;
  bottom: 0;
  background: $color-white;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;

  :global(.buttonsContainer) {
    max-width: 60rem;
    margin: 0 auto;
    flex: 1;
  }
}

.footerSpacer {
  height: $footer-height;
  width: 100%;
}

.container {
  height: calc(100% - 7rem);
  max-height: 100%;
  overflow: auto;
}

.parametersWrapper {
  padding: 4rem;

  td > :global(.MuiFormControl-fullWidth) {
    min-width: 14rem;
  }
}
