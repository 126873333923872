@import '../../../../styles/variables';

body {
  .modelDetail {
    overflow: hidden;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    .content {
      min-height: 100%;
      background-color: $color-light-background;
      flex-grow: 1;
    }
  }
}
