@import '../../styles/variables';
$box-layout-background: #3560e0 !default;
$box-layout-container-background: #fff !default;

body {
  .page {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    background-color: $box-layout-background;
    background-image: url('../../assets/login-bg@1x.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position: 50% 50%;

    @media (min-width: 158.5rem), (min-height: 83.3rem) {
      background-image: url('../../assets/login-bg@2x.jpg');
    }
  }

  .container {
    margin: 10rem auto 10rem;
    // FIXME: why did I have to put it here explicitly?
    width: 37.8rem;
    background-color: $box-layout-container-background;
    padding: 2.5rem 4.5rem;
  }

  .footer {
    top: auto;
    bottom: 0;
    color: black;
  }

  .heading {
    padding: 0 0.4rem;
  }

  .title {
    font-size: 2rem;
    font-weight: normal;
    color: #000;
  }

  .subtitle {
    margin-bottom: 2.9rem;
    font-size: 1.4rem;
    color: #000;
  }

  .links {
    margin: 1.4rem 0 2rem;
    display: flex;
    align-content: flex-start;

    > *:not(:last-child) {
      display: flex;
      margin-right: 2.5rem;
      align-items: center;
    }

    .linkButton {
      color: $primary-color;

      &:hover {
        text-decoration: underline;
        background: none;
      }
    }
  }
}
