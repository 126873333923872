@import '../../styles/variables';

.wrapper {
  display: flex;
  flex-grow: 1;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  overflow: visible;
  min-width: 85rem;

  .header {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    height: 4rem;
    width: 100%;
    align-items: center;
    color: $color-dark-text;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .logo {
    width: 2.4rem;
    height: 1.8rem;
    margin: 0 1.4rem 0 1.4rem;
  }

  .button {
    background-color: #00857c;
    width: 25%;

    &:hover {
      background-color: #00857c;
    }
  }

  .mainLogo {
    height: 7rem;
    width: 7rem;
  }

  .content {
    text-align: center;
    color: black;
    margin-top: 7rem;
  }
}
