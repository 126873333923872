@import '../../../../styles/variables';

.pageTitle {
  color: #389d8f;
  font-size: 3.2rem;
}

.visualElements {
  margin-left: 4rem;
  margin-top: 4rem;
}

@media print {
  :global(.toolbar),
  :global(.visualization-stepper) {
    display: none !important;
  }
  .visualElements {
    margin: 0;
  }
  @page {
    margin: 1rem;
    size: landscape !important;
  }
  section {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }
  .chart {
    zoom: 90% !important;
    min-height: 25rem;
    margin: 1rem 0;
  }
  .table_wrapper {
    display: block;
    zoom: 70% !important;
    h3 {
      font-size: 2.1rem;
    }
    td,
    th {
      font-size: 1.1vmax;
    }
  }
}
