body {
  .gridContainer > div > table {
    table-layout: fixed;
  }

  .indicator {
    height: 0.6rem;
  }

  .searchPanel {
    padding: 0.5rem;
  }
  .colNameHeading {
    width: 11rem;
  }
  .colModelTypeHeading {
    width: 9rem;
  }
  .colCreatorHeading {
    width: 11rem;
  }
  .colDateHeading {
    width: 19.5rem;
  }
  .colStatusHeading {
    width: 11rem;
  }
  .previewFooterContent {
    margin-top: 20px;
  }
  .previewBold {
    font-weight: bold;
  }
}
