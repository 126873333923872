.icon {
  margin-right: 2.5rem;
  flex-shrink: 0;
}

.text {
  font-size: 1.6rem;
}

:global(.darkBlueTheme) {
  .icon,
  .text {
    color: white;
  }
}
