@import '../../../../../styles/variables';

$userCard-max-width: 37.6rem;

body {
  .wrapper {
    //margin-top: 1rem;
    //margin-bottom: 2rem;

    &.wrapperExpanded {
      padding-left: 6rem;
    }

    //word-break: break-all;
  }
  .classificationIcon {
    width: auto;
    height: 3.5rem;
    margin: 0.5rem 2rem 0 0;
  }
  .userCardWrapper {
    max-width: $userCard-max-width;
    margin: 1rem 1rem 1rem 0rem;
  }

  .tile {
    padding: 1rem 1rem 1rem 0rem;

    .label {
      color: $color-light-text-label;
      font-size: 1.2rem;
      line-height: 1.2rem;
    }

    .value {
      font-size: 1.6rem;
      line-height: 1.6rem;
      font-weight: bold;
    }

    .icon {
      width: 3.2rem;
      height: 3.2rem;
      display: block;
      margin: 0.5rem 0.8rem auto;

      & > svg {
        width: 100%;
        height: 100%;
      }
    }

    .attachment {
      background-color: $color-dark-background;
      color: $color-dark-text;
    }

    &.tileModelName {
      padding-top: 1rem;

      .value {
        font-size: 2rem;
        line-height: 2rem;
      }
    }

    &.tileHeadline {
      color: $primary-color;
      padding-top: 3rem;

      .label {
        font-size: 2rem;
        line-height: 2rem;
        color: inherit;
      }
    }

    &.tileLong {
      .value {
        font-size: 1.3rem;
        line-height: 2rem;
        font-weight: normal;
      }
    }
  }

  :global(.darkGreyTheme) .tile {
    .label,
    .icon > svg {
      color: $color-darkGrey-text2;
    }
  }
}
