.animationWrapper {
  position: relative;
}
.container {
  position: absolute;
  left: 0;
  top: -0.8rem;
  display: flex;
  align-items: center;

  > svg {
    margin-right: 1rem;
  }
}

:global(.execution-status-enter) {
  opacity: 0;
  transform: translate(10rem, 0rem);
}

:global(.execution-status-enter-active) {
  opacity: 1;
  transform: translate(0rem, 0rem);
  transition: all 0.3s;
}

:global(.execution-status-exit) {
  opacity: 0;
  transform: translate(10rem, 0rem);
  transition: all 0.3s;
}
