@import '../../styles/variables';

.container {
  background: linear-gradient(180deg, rgba(0, 133, 119, 1) 0%, rgba(12, 35, 64, 1));
  width: 100%;
  height: 100%;
  display: flex;
}
.wrapper {
  display: flex;
  flex-grow: 1;
  height: 97vh;
  flex-direction: column;
  overflow: visible;

  .header {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    height: 2.5rem;
    width: 100%;
    margin-bottom: 2rem;
    align-items: center;
    color: $color-dark-text;
    background-color: #203354;
  }
  .title {
    display: flex;
    align-items: center;
    font-size: 0.9rem;

    &:hover {
      text-decoration: none;
    }

    .logo {
      width: 1.5rem;
      height: 1.5rem;
      margin: 0 1rem 0 1rem;
    }
  }
}

.border {
  border: 1rem solid grey;
  width: 120rem;
  height: 28rem;
}

.dialogBox {
  border: 1rem solid grey;
  width: 120rem;
  min-height: 15rem;
}

.registrationContainer {
  width: 100%;
  height: 100%;
  display: flex;
}
