@import '../../../../styles/variables';

.selectedLeftIndicator:global(.MuiListItem-root) {
  border-left: 5px solid transparent;
  //padding-left: 10px;
  &:global(.Mui-selected) {
    border-left: 5px solid #{$primary-color};
    background-color: transparent;
  }
}

.selectedIconIndicator {
  &:global(.Mui-selected) {
    background-color: transparent;
    :global(.MuiListItemIcon-root) {
      border: 0.2rem solid #{$primary-color};
    }
  }
}
