.container {
  padding-top: 2rem;
  display: flex;
  flex-direction: row;
}

.icon {
  margin-right: 1rem;
  flex-shrink: 0;
}
