@import '../../../styles/variables';
:global(.expandedButton) {
  .buttonWrapper {
    transition: background 0.5s;
  }
  :global(.MuiList-root) {
    width: 19rem;
    padding: 0 !important;
    :global(.MuiTypography-body2) {
      font-size: 1.6rem;
      color: #565656;
    }
  }
  .buttonWrapperOpen {
    background: #f3f3f3;
    box-shadow: 0 0 0.4rem 0.1rem #8d8d8d57;
  }

  :global(.MuiPaper-root) {
    margin-top: 0.3rem;
    background: #f3f3f3;
    box-shadow: 0 0 0.4rem 0.1rem #8d8d8d57;
  }

  :global(.MuiIconButton-root) {
    color: #171717 !important;
  }

  :global(.MuiIconButton-sizeSmall) {
    padding: 0.75rem;
  }
}
.actionlist::before {
  content: '';
  position: absolute;
  right: 0;
  display: block !important;
  background: #f3f3f3;
  top: -0.4rem !important;
  width: 3.86rem !important;
  height: 0.4rem !important;
}

.actionlist {
  &:global(.MuiList-root > *) {
    padding: 0 2rem !important;
    height: 4rem !important;
  }
}

.redButton {
  background-color: $color-red-400 !important;
  :global(.MuiTypography-body2) {
    color: $color-white !important;
  }
  &:global(.MuiListItem-button:hover) {
    background-color: $color-red-600 !important;
  }
  &:global(.Mui-disabled) {
    background-color: $color-red-50 !important;
    opacity: unset !important;
  }
}
