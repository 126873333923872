@import '../../../../styles/variables';

$footer-height: 7rem;

.container {
  z-index: 10;
  width: calc(100% - 4rem);
  height: $footer-height;
  background: $color-white;
  position: fixed;
  bottom: 0;
  left: 4rem;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);

  .buttonsContainer {
    max-width: 90rem;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    & > * {
      margin-left: 1rem;
    }
  }
}

.spacer {
  height: $footer-height;
  width: 100%;
}
