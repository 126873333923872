@import '../../../styles/variables';

.sectionWrapper {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}
.section {
  width: 100%;
  overflow: auto;
  position: relative;
}
