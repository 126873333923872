@import '../../../../styles/variables';

body {
  .gridContainer > div > table {
    table-layout: fixed;
  }
  .initialDialog {
    width: 450px;
    position: absolute;
    left: 10px;
    top: 50px;
  }
  .selectedFilter {
    background: #1e90ff;
  }
  .dialogContent {
    font-weight: bold;
    font-size: 22px;
  }
  .closeButton {
    float: right;
    position: relative;
    top: -6px;
  }

  .dropDownWrapper,
  .disable {
    display: inline-block;
    position: relative;
    margin: 4px;
    width: 195px;
    .autoComplete {
      width: 175px;
    }
  }

  .filtersContainer {
    min-height: 60px;
    background: #fff;
    padding: 5px;
  }
  .indicator {
    height: 0.6rem;
  }

  .searchPanel {
    padding: 0.5rem;
  }
  .colNameHeading {
    //width: calc(20vw + 10rem);
  }
  .colModelTypeHeading {
    width: 9rem;
  }
  .colCreatorHeading {
    width: 11rem;
  }
  .colDateHeading {
    width: 19.5rem;
  }
  .colStatusHeading {
    width: 11rem;
  }
}
