.treeContainer {
  display: inline-block;
  margin-right: auto;
}

body {
  .formWrapper {
    margin-top: 5rem;
    margin-left: 1rem;
  }
}
