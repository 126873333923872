@import '../variables';

$offsetLeft: '0.7rem';

.fileTree {
  ol {
    padding-left: 2em;
    margin: 0;
  }

  li {
    position: relative;
    list-style: none;

    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: -#{$offsetLeft};
      display: block;
      width: 0;
      border-left: 0.1rem solid #777;
      content: '';
    }

    &:last-child:before {
      height: 1rem;
      bottom: auto;
    }

    .mainFile {
      font-weight: 600;
    }
  }

  .treeNode {
    padding-left: 0.5rem;
    height: 4rem;
    position: relative;
    display: flex;
    text-align: left;
    align-items: center;
    margin-bottom: 1px;
    background-color: $color-grey-50;

    &:after {
      position: absolute;
      top: -1px;
      left: -#{$offsetLeft};
      display: block;
      height: 2.1rem;
      width: #{$offsetLeft};
      border-bottom: 0.1rem solid #777;
      border-left: 0.1rem solid #777;
      content: '';
    }

    .fileNameContainer {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 3.6rem;
      border-left: 0.6rem solid transparent;
      padding: 0.2rem 1rem;
      margin-left: 0.5rem;
      overflow: hidden;

      .MuiCheckbox-root {
        padding: 5px;
      }
    }

    &.clickable {
      cursor: pointer;
    }

    &.queuedNew,
    &.queuedOverwrite {
      .fileNameContainer {
        border-left-color: $color-green-400;
      }
    }

    &.queuedError,
    &.uploadError,
    &.parsingError,
    &.deleteError,
    &.markedForDeletion {
      .fileNameContainer {
        border-left-color: $color-red-400;
      }
    }

    &.bitbucketNew {
      .fileNameContainer {
        border-left-color: $color-violet-200;
      }
    }
    &.bitbucketOld {
      .fileNameContainer {
        border-left-color: $color-violet-600;
      }
    }

    .fileName {
      flex-grow: 1;
      padding-right: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .rootNodeIcon {
    margin-left: 1rem;
  }

  .nodeIcon {
    display: inline-block;
    width: 2.2rem;
    margin-right: 0.3rem;
    flex-shrink: 0;
  }

  .folderToggle {
    text-decoration: none;
  }
}

@mixin fileTreeTheme($iconColor, $nameColor, $nameBackgroundColor, $nodeBackgroundColor) {
  .treeNode,
  .nodeIcon {
    color: $iconColor;
    .fileNameContainer {
      background: $nameBackgroundColor;
      color: $nameColor;
    }
  }

  .treeNode {
    background-color: $nodeBackgroundColor;
  }
}

.whiteTheme {
  @include fileTreeTheme($color-light-text, $color-light-text, $color-light-background, $color-grey-50);
}

.darkBlueTheme {
  @include fileTreeTheme($color-darkBlue-text, $color-light-text, $color-darkBlue-input-placeholder, transparent);
}
