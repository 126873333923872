@import '../../../styles/variables.scss';

body {
  .container {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(0rem, 0rem);
    transition: transform 0.3s ease-in-out;
    flex-wrap: nowrap;

    &.info {
      background-color: $info-color;
    }
    &.success {
      background-color: $success-color;
    }
    &.warning {
      background-color: $warning-color;
    }
    &.error {
      background-color: $error-color;
    }
  }

  .message {
    display: flex;
    align-items: center;
    text-align: left;
  }

  .icon {
    font-size: 2rem;
    opacity: 0.9;
    margin-right: 1rem;
  }

  .stackTraceLink {
    width: 12rem;
    display: block;
    font-size: medium;
    text-align: center;
    background-color: #ffffff;
    color: #ff0000;
    cursor: pointer;
  }

  .stackTraceModal {
    z-index: 1401;
  }
}
