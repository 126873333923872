@import '../../styles/variables';

.wrapper {
  display: flex;
  flex-grow: 1;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  overflow: hidden;
  min-width: 85rem;

  .header {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    height: $header-height;
    width: 100%;
    align-items: center;
    color: $color-dark-text;
    background-color: #000000;
    justify-content: space-between;
  }

  .container,
  .zoomContainer {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    min-width: 0;
  }

  .container {
    height: calc(100% - (#{$header-height} * 2));
  }

  .leftSideBar {
    width: 4rem;

    & > hr {
      color: white;
    }

    &__bottom {
      margin-top: auto;
    }

    :global(.MuiListItem-root:hover .MuiListItemIcon-root) {
      background-color: #000000;
    }

    :global(.MuiListItemIcon-root) {
      margin: auto;
    }
  }
}
