@import '../variables';

body {
  .darkGreyTheme,
  .darkTheme {
    .MuiButton-containedPrimary.Mui-disabled {
      background: rgba(0, 99, 247, 0.2);
      color: $color-dark-input-placeholder;
    }

    .MuiButton-contained.Mui-disabled {
      background: rgba(220, 220, 220, 0.2);
      color: $color-dark-input-placeholder;
    }
  }
}
