@import '../../../../styles/variables';

body {
  .gridContainer > div > table {
    table-layout: fixed;
  }

  .indicator {
    height: 0.6rem;
  }

  .searchPanel {
    padding: 0.5rem;
  }
  .colNameHeading {
    //width: calc(20vw + 10rem);
  }
  .colModelTypeHeading {
    width: 9rem;
  }
  .colCreatorHeading {
    width: 11rem;
  }
  .colDateHeading {
    width: 19.5rem;
  }
  .colStatusHeading {
    width: 11rem;
  }
}
