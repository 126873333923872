@import '../../../styles/variables';

body {
  .filterCheckboxes {
    padding-left: 2rem;
    padding-right: 2rem;
    margin: 0.2rem 2rem 0.2rem 2rem;
    border-style: solid;
    border-color: $color-light-border;
    border-width: 0.1rem;
  }

  .headerLabel {
    display: flex;
    font-weight: 600;
  }

  .nonClickable {
    pointer-events: none;
  }

  .buttonBox {
    padding: 1.8rem 1.5rem 1.8rem 1.5rem;
    float: right;
  }

  .button {
    margin: 1rem;
  }

  .doubleIconWrapper {
    position: relative;
  }

  .doubleIconLeft {
    position: absolute;
    bottom: 0.2rem;
    left: 0;
  }

  .doubleIconRight {
    position: absolute;
    bottom: 0.2rem;
    left: 1.6rem;
    max-width: 1.2rem;
    max-height: 1.2rem;
  }
}
