@import '../../../styles/variables';

body {
  .fieldset {
    display: block;
    margin-bottom: 1rem;

    &:not(:last-child) {
      padding-bottom: 0.5rem;
      border-bottom: 0.1rem solid grey;
    }
  }
  .collapseToggle {
    width: 100%;
    padding: 0.3rem 0;
    cursor: pointer;
    color: $color-white;
    user-select: none;

    &:global(.Mui-focused) {
      color: $color-white;
    }
  }
  .collapseToggleIcon {
    margin-top: -0.3rem;
    float: right;
  }
  .clearFilterButton {
    margin-top: -0.3rem;
    float: right;
  }
  .label {
    margin-right: 0;
  }
  .optionLabel {
    font-size: 90%;

    max-width: 20rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    margin-top: 0.7rem;
    user-select: none;
  }
  .checkboxRoot {
    padding: 0.1rem 0.5rem 0.1rem 0.9rem;
    margin-top: 0.5rem;
  }
  .radioRoot {
    padding: 0 0.9rem;
    margin-top: 0.5rem;
  }
}
