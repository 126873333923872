@import '../../styles/variables';
$login-background: #3560e0 !default;
$login-form-background: #fff !default;

body {
  .page {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    background-color: $login-background;
    background-image: url('../../assets/login-bg@1x.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position: 50% 50%;

    @media (min-width: 158.5rem), (min-height: 83.3rem) {
      background-image: url('../../assets/login-bg@2x.jpg');
    }
  }

  .form {
    margin: 10rem auto 10rem;
    // FIXME: why did I have to put it here explicitly?
    width: 37.8rem;
    background-color: $login-form-background;
    padding: 2.5rem 4.5rem;
  }

  .footer {
    top: auto;
    bottom: 0;
    color: black;
  }

  .version {
    flex-grow: 1;
    font-size: 1.2rem;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footerIcon {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 2.2rem;
  }
}
