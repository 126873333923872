@import '../../../../../styles/variables.scss';

.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.container {
  display: inline-block;
  background: white;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);

  width: 57rem;
  position: relative;
  border: 2px solid $color-light-border;

  &.selectable {
    cursor: pointer;
    &:hover {
      border: 0.2rem dashed $primary-color;
    }
  }

  &.current {
    border: 0.2rem solid $primary-color;
  }

  &.retired {
    background-color: $color-light-border;
  }

  &.blueShadow {
    box-shadow: 0 4px 20px 0 #0062ff80;
  }

  h2 {
    color: $color-blueText2;
    font-size: 1.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: normal;

    svg {
      margin-right: 0.2rem;
    }
  }
}

.leftSection {
  white-space: pre-wrap;
  background: $color-light-background;
  width: 19rem;
  padding: 1.5rem;
  position: relative;
  color: $color-blueText1;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 2.4rem;
  flex-shrink: 0;

  .spacer {
    display: flex;
    align-items: stretch;
  }
}

.leftSectionDynamic {
  white-space: pre-wrap;
  width: 35rem;
  padding: 1.5rem;
  position: relative;
  color: $color-blueText1;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 2.4rem;
  flex-shrink: 0;

  .spacer {
    display: flex;
    align-items: stretch;
  }
}
.tileItem {
  padding: 0px !important;
}
.cardItemIcon {
  padding-bottom: 20px;
}

.rightSection {
  flex-grow: 1;
  border-left: 3px solid #{$color-light-text-secondary};
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rightSectionDynamic {
  flex-grow: 1;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.rightSectionContainer {
  width: 90%;
}

.actionContainer {
  position: absolute;
  right: 5px;
  top: 5px;
}

.dynamicActionContainer {
  position: absolute;
  right: 5px;
  width: 28px;
}
