@import '../../../styles/variables';

.container {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: $header-height;
  width: 100%;
  align-items: center;
  color: $color-dark-text;
  background-color: $color-dark-background;
  justify-content: space-between;
  border-top: 1px solid #{$color-dark-background-header};
}
